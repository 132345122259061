import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8001_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8001 Full HD Installation",
  "path": "/Indoor_Cameras/IN-8001_HD/Quick_Installation/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8001 Full HD Indoor IP Camera Quick Installation",
  "image": "./P_SearchThumb_IN-8001HD_Quick_Installation.png",
  "social": "/images/Search/P_SearchThumb_IN-8001HD_Quick_Installation.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-8001 Full HD Installation' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8001 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8003HD_Quick_Installation.png' twitter='/images/Search/P_SearchThumb_IN-8003HD_Quick_Installation.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8001_HD/Schnell_Installation/' locationFR='/fr/Indoor_Cameras/IN-8001_HD/Quick_Installation/' crumbLabel="Installation" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "quick-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quick-installation",
        "aria-label": "quick installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Quick Installation`}</h2>
    <p>{`For the wall mount installation, there are two ways to install the IN-8001 FullHD camera.`}</p>
    <h3 {...{
      "id": "wall-mount-by-magnet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wall-mount-by-magnet",
        "aria-label": "wall mount by magnet permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Wall mount by Magnet`}</h3>
    <p>{`The camera holds a magnet in the bottom of the stand. Use the round metal plate and install it to the wall. Then simply attach the stand to the metal plate and adjust the orientation to your needs.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/07a430ffd16cb087ca357057e1d4087c/da994/IN-8001_Quick_Installation_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAj0lEQVQY032QUQ7DMAhDe//Lbt3SEMCGqY1aZV00f0GkJ168ZDCDQRAeRND7kENIxhEAEXG9Lx3uvJte6w02szgzh+Fm2ggbYVOtWwE8M0fyC86gaZO6uemoLbWW98tMSbr7/HIGD7bcYDPVJrsR6X/+fGr7qA1Am7g7RCAy1+6FZ8ZvYXvPZDwfXNe+d/gDfvqfJ8NPbLsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07a430ffd16cb087ca357057e1d4087c/e4706/IN-8001_Quick_Installation_01.avif 230w", "/en/static/07a430ffd16cb087ca357057e1d4087c/d1af7/IN-8001_Quick_Installation_01.avif 460w", "/en/static/07a430ffd16cb087ca357057e1d4087c/7f308/IN-8001_Quick_Installation_01.avif 920w", "/en/static/07a430ffd16cb087ca357057e1d4087c/e1c99/IN-8001_Quick_Installation_01.avif 1380w", "/en/static/07a430ffd16cb087ca357057e1d4087c/0cfbf/IN-8001_Quick_Installation_01.avif 1487w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/07a430ffd16cb087ca357057e1d4087c/a0b58/IN-8001_Quick_Installation_01.webp 230w", "/en/static/07a430ffd16cb087ca357057e1d4087c/bc10c/IN-8001_Quick_Installation_01.webp 460w", "/en/static/07a430ffd16cb087ca357057e1d4087c/966d8/IN-8001_Quick_Installation_01.webp 920w", "/en/static/07a430ffd16cb087ca357057e1d4087c/445df/IN-8001_Quick_Installation_01.webp 1380w", "/en/static/07a430ffd16cb087ca357057e1d4087c/94fbd/IN-8001_Quick_Installation_01.webp 1487w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07a430ffd16cb087ca357057e1d4087c/81c8e/IN-8001_Quick_Installation_01.png 230w", "/en/static/07a430ffd16cb087ca357057e1d4087c/08a84/IN-8001_Quick_Installation_01.png 460w", "/en/static/07a430ffd16cb087ca357057e1d4087c/c0255/IN-8001_Quick_Installation_01.png 920w", "/en/static/07a430ffd16cb087ca357057e1d4087c/b1001/IN-8001_Quick_Installation_01.png 1380w", "/en/static/07a430ffd16cb087ca357057e1d4087c/da994/IN-8001_Quick_Installation_01.png 1487w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/07a430ffd16cb087ca357057e1d4087c/c0255/IN-8001_Quick_Installation_01.png",
              "alt": "IN-8001 Full HD Installation",
              "title": "IN-8001 Full HD Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "direct-wall-mount",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#direct-wall-mount",
        "aria-label": "direct wall mount permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Direct Wall mount`}</h3>
    <p>{`Alternatively, install two screws directly in the wall or ceiling and then attach the camera stand using the arresting lock.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/745c52bf35c639e453d38301884f65d6/f705a/IN-8001_Quick_Installation_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAs0lEQVQoz3VR0Y7DMAjr/3/pHnY7rVvTgG04NTl1bZVZvARhbIcpQxkSEWJm9OdWB0SEdOp0TH00RMIJF9GKlzlJJNW27IumXSpEuA2VMwIAyLDK30fvXMkkPs4PmOen1cKQ5idutzEZbt38kRwRj5+71RXulEAObGeorqUs7+X9upBLWeBmVslNHsAgc7Od/853cua6Fri5Wwu+YURuhrebnTOTdLMueLzZiSxi/NtfTv0HlC0V+AtkZWoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/745c52bf35c639e453d38301884f65d6/e4706/IN-8001_Quick_Installation_02.avif 230w", "/en/static/745c52bf35c639e453d38301884f65d6/d1af7/IN-8001_Quick_Installation_02.avif 460w", "/en/static/745c52bf35c639e453d38301884f65d6/7f308/IN-8001_Quick_Installation_02.avif 920w", "/en/static/745c52bf35c639e453d38301884f65d6/e1c99/IN-8001_Quick_Installation_02.avif 1380w", "/en/static/745c52bf35c639e453d38301884f65d6/66cd2/IN-8001_Quick_Installation_02.avif 1493w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/745c52bf35c639e453d38301884f65d6/a0b58/IN-8001_Quick_Installation_02.webp 230w", "/en/static/745c52bf35c639e453d38301884f65d6/bc10c/IN-8001_Quick_Installation_02.webp 460w", "/en/static/745c52bf35c639e453d38301884f65d6/966d8/IN-8001_Quick_Installation_02.webp 920w", "/en/static/745c52bf35c639e453d38301884f65d6/445df/IN-8001_Quick_Installation_02.webp 1380w", "/en/static/745c52bf35c639e453d38301884f65d6/e8e93/IN-8001_Quick_Installation_02.webp 1493w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/745c52bf35c639e453d38301884f65d6/81c8e/IN-8001_Quick_Installation_02.png 230w", "/en/static/745c52bf35c639e453d38301884f65d6/08a84/IN-8001_Quick_Installation_02.png 460w", "/en/static/745c52bf35c639e453d38301884f65d6/c0255/IN-8001_Quick_Installation_02.png 920w", "/en/static/745c52bf35c639e453d38301884f65d6/b1001/IN-8001_Quick_Installation_02.png 1380w", "/en/static/745c52bf35c639e453d38301884f65d6/f705a/IN-8001_Quick_Installation_02.png 1493w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/745c52bf35c639e453d38301884f65d6/c0255/IN-8001_Quick_Installation_02.png",
              "alt": "IN-8001 Full HD Installation",
              "title": "IN-8001 Full HD Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      